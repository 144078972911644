import styled from '@emotion/styled'
import React, { Component } from 'react'
import Container from '../components/container'
import Header from '../components/header'
import Pages from '../enums/pages';
import AppContext from '../util/context'
import Background, { Raised } from '../components/background'
import Footer from '../components/footer'
import { DateTime } from 'luxon'
import Terminal from '../util/terminal'
import ReactGA from '../components/ga'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faTimes, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

const Detect = styled.div`
  background-color: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Coords = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  width: 350px;
  height: 20px;
  z-index: 10;
`

const Caret = styled.div`
  display: inline-block;
  background-color: white;
  width: 5px;
  height: 16px;
  verticalAlign: top;
  margin-bottom: -2px;
  animation: flash 1.2s ease-in-out infinite;
  @keyframes flash {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
`

const TerminalContainer = styled.div`
  background-color: rgb(0,40,51);
  max-height: 575px;
  overflow-y: scroll;
  border-radius: 0 0 10px 10px;
  flex-grow: 1;
  font-family: "Lucida Console", Monaco, monospace;
  padding: 5px;
  white-space: pre-wrap;
  line-height: 16pt;

  :focus {
    outline: none;
  }
`

const CommandLinks = styled.div`
  cursor: pointer;
  opacity: 0.7;
  :hover {
    opacity: 1;
  }
  font-size: 2rem;
`

const Close = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: red;
  margin: 0 3px;
`
const Minimize = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: yellow;
  margin: 0 3px;
`
const Expand = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: green;
  margin: 0 3px;
`

const Action = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: 10px;
  :hover {
    opacity: 1;
  }
`

class DetectContainer extends Component<{ windowWidth: number, windowHeight: number, children: (raised: Raised, offset: number) => JSX.Element }, { x: number, y: number, raised: Raised }> {
  enableCoords: boolean

  constructor(props) {
    super(props)
    this.enableCoords = false;
    this.state = {
      x: 0,
      y: 0,
      raised: null
    }
  }

  mouseMove = (evt: React.MouseEvent) => {
    this.setState({ x: evt.clientX, y: evt.clientY })
  }

  render() {
    let { x, y } = this.state;
    let { windowHeight, windowWidth, children } = this.props;
    let windowMidX = windowWidth / 2;
    let raised = x >= windowMidX ? Raised.Right : Raised.Left;
    let xVal = Math.abs(x - windowMidX) / windowMidX * 16 + 1;
    let offset = Math.log2(xVal) / 3;

    return (
      <Detect onMouseMove={this.mouseMove}>
        {this.enableCoords && (
          <Coords>
            x: { x }, y: { y }, height: {windowHeight}, width: {windowWidth}, offset: {offset.toFixed(4)}
          </Coords>
        )}
        { children(raised, offset) }
      </Detect>
    )
  }
}

class Home extends Component<any, { terminalText: string, hasFocus: boolean, closed: boolean }> {
  terminal: Terminal
  terminalRef: React.RefObject<HTMLDivElement>

  constructor(props) {
    super(props)
    this.terminal = new Terminal(`Last login: ${DateTime.local().toFormat("ccc LLL dd hh:mm a")}\n$:`)
    this.terminalRef = React.createRef()
    this.state = {
      terminalText: this.terminal.terminalText,
      hasFocus: false,
      closed: false
    }
  }

  typing = e => {
    if (this.state.closed) {
      return;
    }

    if (e.which == 13) {
      this.terminal.issueCommand()
    } else {
      this.terminal.typeAlong(String.fromCharCode(e.which))
    }
     
    this.setState({ terminalText: this.terminal.terminalText })
    setTimeout(() => this.terminalRef.current.scroll({ top: this.terminalRef.current.scrollHeight }), 100)
  }

  keyDown = e => {
    if (e.ctrlKey && e.which == 76) {
      this.terminal.issueCommand("clear")
      this.setState({ terminalText: this.terminal.terminalText })
    } else if (e.ctrlKey && e.which == 67) {
      this.terminal.close();
      this.setState({ terminalText: this.terminal.terminalText, closed: true });
    } else if (e.which == 8) {
      this.terminal.backspace()
      this.setState({ terminalText: this.terminal.terminalText })
    }
  }

  focus = () => {
    this.setState({ hasFocus: true })
  }

  blur = () => {
    this.setState({ hasFocus: false })
  }

  sendCommand(cmd: string) {
    if (this.state.closed) {
      this.setState({ terminalText: `${this.terminal.terminalText}\n<error> Terminal is closed please refresh the page to restart it.` })
      return;
    }

    this.terminal.issueCommand(cmd);
    this.setState({ terminalText: this.terminal.terminalText })
    setTimeout(() => this.terminalRef.current.scroll({ top: this.terminalRef.current.scrollHeight }), 100)
  }

  render() {
    return (
      <Container>
        <Header page={Pages.Home}></Header>
        <div style={{ height: '100%', width: '100%', position: 'relative' }}>
          <AppContext.Consumer>
          {({ windowHeight, windowWidth }) => (
            <DetectContainer windowHeight={windowHeight} windowWidth={windowWidth}>
              { (raised, offset) => (
                <>
                  <Background raised={raised} offset={offset} />
                  <div style={{ zIndex: 5, color: "white", width: "50%", display: "flex", position: "relative" }}>
                      <div style={{textAlign: "right", width: "35%", padding: "0 10px" }}>
                        <div style={{ fontSize: "5rem", opacity: 0.9 }}>
                          <div style={{ position: "relative" }}>Jason</div>
                          <div style={{ top: "-40px", position: "relative" }}>Gao</div>
                        </div>
                        <div style={{ fontSize: "2rem" }}>
                          <CommandLinks onClick={() => this.sendCommand('eat')}><FontAwesomeIcon icon={faAngleRight} /> Eat</CommandLinks>
                          <CommandLinks onClick={() => this.sendCommand('sleep')}><FontAwesomeIcon icon={faAngleRight} /> Sleep</CommandLinks>
                          <CommandLinks onClick={() => this.sendCommand('travel')}><FontAwesomeIcon icon={faAngleRight} /> Travel</CommandLinks>
                          <CommandLinks onClick={() => this.sendCommand('software')}><FontAwesomeIcon icon={faAngleRight} /> Software</CommandLinks>
                        </div>
                      </div>
                      <div style={{ width: "65%" }}>
                        <div style={{ width: "100%", height: "600px", display: "flex", padding: "0 10px", flexDirection: "column", filter: "drop-shadow(2px 2px 3px rgba(0,0,0,0.5))" }}>
                          <div style={{ backgroundColor: "#38444c", height: "25px", borderRadius: "10px 10px 0 0" }}>
                            <div style={{ width: "65px", height: "25px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <Close>
                                <Action>
                                  <FontAwesomeIcon icon={faTimes} />
                                </Action>
                              </Close>
                              <Minimize>
                                <Action>
                                  <FontAwesomeIcon icon={faMinus} />
                                </Action>
                              </Minimize>
                              <Expand>
                                <Action>
                                  <FontAwesomeIcon icon={faPlus} />
                                </Action>
                              </Expand>
                            </div>
                          </div>
                          <TerminalContainer tabIndex={0} onKeyPress={this.typing} onFocus={this.focus} onBlur={this.blur} onKeyDown={this.keyDown} ref={this.terminalRef} className="scrollbar">
                            {this.state.terminalText}{ this.state.hasFocus && !this.state.closed && <Caret />}
                          </TerminalContainer>
                        </div>
                      </div>
                  </div>
                </>
              )}
            </DetectContainer>
          )}
          </AppContext.Consumer>
        </div>
        <Footer />
      </Container>
    )
  }
}

const HomeGa = ({ location }) => <ReactGA pathname={location.pathname}><Home /></ReactGA>

export default HomeGa