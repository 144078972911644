import { sendEvent } from '../components/ga'

const commands = {
  help: {
    text:
      "version 1.0\n\nThe following are valid commands:\n\neat: Find out what kinds of food I like to eat\nsleep: Do you love sleeping? Because I do!\nsoftware: List of software experience\ntravel: Where have I been and where I'd love to go\nclear (Ctrl+L): Clear previous terminal text\nclose (Ctrl+C): end terminal session",
  },
  eat: {
    text:
      'Favorite kinds of food include:\n\n- Japanese food (sushi, ramen, sashimi, donburi, udon, katsu)\n- Chinese food (mapo tofu, xiaolong bao, dumplings, fried rice)\n- Italian food (pasta, pizza)\n- Steaaaaaaks',
  },
  sleep: {
    text:
      'You like to sleep too?!!\n\nI try to sleep at least 7 hours a day, but that never seems to happen',
  },
  software: {
    text:
      "Over the years I've accumulated extensive experience across multiple languages and technologies:\n\n- Javascript\n- Typescript\n- C#\n- React\n- ServiceStack\n- AWS Services (Lambda, S3, Cloudfront, DynamoDb, AppSync, API Gateway, Elasticache, Elasicsearch, EC2)\n- PHP\n- Golang\n- Webpack",
  },
  travel: {
    text:
      "My second passion in life is to travel. Countries and places I've been to: \n\n- Norway\n- Peru\n- Chile\n- Bolivia\n- Brazil\n- Japan\n- Hong Kong\n- Taiwan\n- China\n- New Zealand\n- England\n- France\n- Belgium\n- Netherlands\n- Italy\n- Bahamas\n- Costa Rica",
  },
  clear: {
    text: '',
    action: (text, caret) => `${caret}:`,
  },
}

class Terminal {
  text: string
  currentCommand: string
  caret: string = '$'

  constructor(initialText?: string) {
    this.text = initialText || ''
    this.currentCommand = ''
  }

  issueCommand(command?: string) {
    let cmd = command || this.currentCommand
    if (commands.hasOwnProperty(cmd)) {
      this.text += `${cmd}\n${commands[cmd].text}\n\n${this.caret}:`
      this.currentCommand = ''
      if (commands[cmd].hasOwnProperty('action')) {
        this.text = commands[cmd].action(this.text, this.caret)
      }
      sendEvent('terminal', cmd)
    } else {
      this.text +=
        cmd +
        `\nInvalid command. Try 'help' for available commands.\n\n${this.caret}:`
      this.currentCommand = ''
    }
  }

  typeAlong(text: string) {
    this.currentCommand += text
  }

  backspace() {
    this.currentCommand = this.currentCommand.slice(0, -1)
  }

  close() {
    this.text += `\nGoodbye...`
  }

  get terminalText() {
    return this.text + this.currentCommand
  }
}

export default Terminal
